import { useEffect, useState } from "react";
import { Card, CardBody, CardSubtitle, CardTitle, Table } from "reactstrap";
import Popup from "../popup/popup";

const ApplyTables = () => {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://demo.annk.info/api/applicants");
      const result = await response.json();

      const newData = result.filter((order) => order.status === "submit");

      setData(newData);
    };

    fetchData();
  }, []);

  console.log(data)
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handlePopupClose = (event) => {
    event.preventDefault(); // Prevent default behavior
    setSelectedImage(null);
  };

    const updateOrder = async (orderID) => {
    try {
      // Define the API endpoint
      const apiUrl = `https://demo.annk.info/api/applicants/${orderID}`;

      const updatedStatus = "done"; // Update this based on your requirements

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: updatedStatus,
        }),
      });

      if (response.ok) {
        console.log(`Order ${orderID} status updated to ${updatedStatus}`);
      } else {
        console.error(`Failed to update order ${orderID} status`);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <CardTitle tag="h5">HỒ SƠ ĐÃ NỘP</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Overview of the applicant
          </CardSubtitle>

          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Nationality</th>
                <th>Passport</th>
                <th>Arrival</th>
                <th>Expiry</th>
                <th>Portrait</th>
                <th>Passport</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {tableData.map((tdata, index) => (
                <tr key={index} className="border-top">
                  <td>
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={tdata.avatar}
                        className="rounded-circle"
                        alt="avatar"
                        width="45"
                        height="45"
                      />
                      <div className="ms-3">
                        <h6 className="mb-0">{tdata.name}</h6>
                        <span className="text-muted">{tdata.email}</span>
                      </div>
                    </div>
                  </td>
                  <td>{tdata.project}</td>
                  <td>
                    {tdata.status === "pending" ? (
                      <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
                    ) : tdata.status === "holt" ? (
                      <span className="p-2 bg-warning rounded-circle d-inline-block ms-3"></span>
                    ) : (
                      <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
                    )}
                  </td>
                  <td>{tdata.weeks}</td>
                  <td>{tdata.budget}</td>
                </tr>
              ))} */}
              {data.map((row) => (
                <tr key={row.id}>
                  <td>{row.orderId}</td>

                  <td>{row.fullName}</td>
                  <td>{row.nationality}</td>
                  <td>{row.passportNumber}</td>
                  <td>{new Date(row.issue).toLocaleDateString("en-GB")}</td>
                  <td>{new Date(row.expiry).toLocaleDateString("en-GB")}</td>
                  <td style={{ width: "100px" }}>
                    <img
                      src={row.portraitData}
                      alt="portrait"
                      width={"100%"}
                      onClick={() => handleImageClick(row.portraitData)}
                    />
                  </td>
                  <td style={{ width: "100px" }}>
                    <img
                      src={row.passportData}
                      alt="passport"
                      width={"100%"}
                      onClick={() => handleImageClick(row.passportData)}
                    />
                  </td>
                  <td>
                    asd<button onClick={() => updateOrder(row.orderId)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        {selectedImage && (
          <Popup imageURL={selectedImage} onClose={handlePopupClose} />
        )}
      </Card>
    </div>
  );
};

export default ApplyTables;
