import { map } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardTitle, Table } from "reactstrap";
import Loading from "../loading";
import Loader from "../../layouts/loader/Loader";
import Q from "q";

export const airport = [
  { value: "HAN", label: "HAN - Hanoi - Noi Bai Airport" },
  { value: "SGN", label: "SGN - Ho Chi Minh - Tan Son Nhat  Airport" },
  { value: "DAD", label: "DAD - Danang - Da Nang  Airport" },
  { value: "VDO", label: "VDO - Quang Ninh - Van Don  Airport" },
  { value: "HPH", label: "HPH - Hai Phong - Cat Bi  Airport" },
  { value: "VII", label: "VII - Nghe An - Vinh  Airport" },
  { value: "HUI", label: "HUI - Hue - Phu Bai  Airport" },
  { value: "CXR", label: "CXR - Khanh Hoa - Cam Ranh  Airport" },
  { value: "DLI", label: "DLI - Lam Dong - Lien Khuong  Airport" },
  { value: "UIH", label: "UIH - Pacify - Phu Cat  Airport" },
  { value: "VCA", label: "VCA - Can Tho - Can Tho  Airport" },
  { value: "PQC", label: "PQC - Kien Giang - Phu Quoc  Airport" },
];

const OrderTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch("https://demo.annk.info/api/orders");
    const result = await response.json();

    const newData = result
      .filter((order) => order.status === "new" || order.status === "draft")
      .reverse();

    setData(newData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getApplicantById = async (id) => {
    const res = await fetch(`https://demo.annk.info/api/applicants/${id}`);
    const result = await res.json();
    return result;
  };

  const updateOrder = async (orderID) => {
    try {
            // Define the API endpoint
      const apiUrl = `https://demo.annk.info/api/orders/${orderID}`;

      const updatedStatus = "process"; // Update this based on your requirements

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: updatedStatus,
        }),
      });
      <Loading />;
      if (response.ok) {
        console.log(`Order ${orderID} status updated to ${updatedStatus}`);
      } else {
        console.error(`Failed to update order ${orderID} status`);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
    // Q.when(getApplicantById(orderID)).then((res) => {
    //   console.log(res);
    // });
    fetchData();
  };

  const cancelOrder = async (orderID) => {
    try {
      // Define the API endpoint
      const apiUrl = `https://demo.annk.info/api/orders/${orderID}`;

      const updatedStatus = "cancel"; // Update this based on your requirements

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: updatedStatus,
        }),
      });

      if (response.ok) {
        console.log(`Order ${orderID} status updated to ${updatedStatus}`);
      } else {
        console.error(`Failed to update order ${orderID} status`);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
    fetchData() && <Loader />;
  };

  return (
    <div>
      {loading && <Loading />}
      <Card>
        <CardBody>
          <CardTitle tag="h5">Order Listing</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Overview of the applicant
          </CardSubtitle>

          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>ID</th>
                <th>Phone</th>
                <th>Name</th>
                <th>Total</th>
                <th>Services</th>
                <th>Purpose</th>
                <th>Port Entry</th>
                <th>Arrival Date</th>
                <th>Fast Track</th>
                <th>Total Prices</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {map(data, (row) => (
                <>
                  <tr key={row.id}>
                    <td>
                      {row?.status === "draft" ? (
                        row.orderId
                      ) : (
                        <Link
                          to={`/apply/${row.orderId}`} // Replace "#" with your actual link or use a proper route
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          {row.orderId}
                        </Link>
                      )}
                    </td>
                    <td>{row.phoneNumber}</td>
                    <td>{row.fullName}</td>
                    <td>{row.applicant}</td>
                    <td>
                      {row.services === "40"
                        ? "E-visa single"
                        : row.services === "65"
                        ? "E-visa multiple"
                        : ""}
                    </td>
                    <td>
                      {row.purpose === "1"
                        ? "Tourist"
                        : row.services === "2"
                        ? "Business"
                        : ""}
                    </td>

                    <td>{row.portentry}</td>
                    <td>
                      {new Date(row.arrivaldate).toLocaleDateString("en-GB")}
                    </td>
                    <td>
                      {row.otherServices === "20"
                        ? "Standard"
                        : row.services === "27"
                        ? "VIP"
                        : ""}
                    </td>
                    <td>{row.totalPrices}</td>
                    <td>{row.status}</td>
                    <td>
                      {row?.status === "draft" ? (
                        <button
                          style={{ border: "none", backgroundColor: "white" }}
                          // TODO: add edit form
                          // onClick={() => updateOrder(row.orderId)}
                        >
                          <i
                            className="bi bi-pencil-square"
                            style={{ color: "blue" }}
                          ></i>
                        </button>
                      ) : (
                        <button
                          style={{ border: "none", backgroundColor: "white" }}
                          onClick={() => updateOrder(row.orderId)}
                        >
                          <i
                            className="bi bi-check-circle"
                            style={{ color: "green" }}
                          ></i>
                        </button>
                      )}
                      <button
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={() => cancelOrder(row.orderId)}
                      >
                        <i
                          className="bi bi-x-circle"
                          style={{ color: "red" }}
                        ></i>
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default OrderTable;
