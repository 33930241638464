import { map } from "lodash";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardTitle, Table } from "reactstrap";
import Popup from "../popup/popup";
import Loading from "../loading";

const Applicant = () => {
  const [applicantData, setApplicantData] = useState("");
  const [orderData, setOrderData] = useState("");
  const { orderId } = useParams();
  const [selectedImage, setSelectedImage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchApplicantData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://demo.annk.info/api/applicants/${orderId}`
        );
        const result = await response.json();
        setApplicantData(result);

        const response1 = await fetch(
          `https://demo.annk.info/api/orders/${orderId}`
        );
        const result1 = await response1.json();
        setOrderData(result1);
      } catch (error) {
        console.error("Error fetching applicant data:", error);
      }
      setLoading(false);
    };

    if (orderId) {
      fetchApplicantData();
    }
  }, [orderId]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handlePopupClose = (event) => {
    event.preventDefault(); // Prevent default behavior
    setSelectedImage(null);
  };

  // const updateOrder = async (orderID) => {
  //   try {
  //     // Define the API endpoint
  //     const apiUrl = `https://demo.annk.info/api/applicants/${orderID}`;

  //     const updatedStatus = "submit"; // Update this based on your requirements

  //     const response = await fetch(apiUrl, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         status: updatedStatus,
  //       }),
  //     });

  //     if (response.ok) {
  //       console.log(`Order ${orderID} status updated to ${updatedStatus}`);
  //     } else {
  //       console.error(`Failed to update order ${orderID} status`);
  //     }
  //   } catch (error) {
  //     console.error("Error updating order status:", error);
  //   }
  // };

  const updateOrderStatus = async (orderID, apiUrls) => {
    try {
      const updatedStatus = "submit"; // Update this based on your requirements

      // Iterate through the array of API URLs
      for (const apiUrl of apiUrls) {
        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: updatedStatus,
          }),
        });

        if (response.ok) {
          console.log(`Order ${orderID} status updated at ${apiUrl}`);
        } else {
          console.error(
            `Failed to update order ${orderID} status at ${apiUrl}`
          );
        }
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }

  };

  const generateApiUrls = (orderId) => {
    const baseUrl1 = "https://demo.annk.info/api/applicants/";
    const baseUrl2 = "https://demo.annk.info/api/orders/";

    const apiUrl1 = `${baseUrl1}${orderId}`;
    const apiUrl2 = `${baseUrl2}${orderId}`;

    return [apiUrl1, apiUrl2];
  };

  return (
    <>
      {loading && <Loading />}
      <Card>
        <CardBody>
          <Link
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <i className="bi bi-arrow-left-short" />
            Back
          </Link>
          <CardTitle tag="h5" className="mt-3">
            Order ID: {orderId}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            <p>
              Người liên hệ: {orderData.fullName}
              {" - "}
              {orderData.phoneNumber}
            </p>
            <p>
              Giá trị: {orderData.totalPrices} USD ~{" "}
              {(24000 * orderData.totalPrices).toLocaleString("en-US", {
                style: "currency",
                currency: "VND",
              })}
            </p>
          </CardSubtitle>

          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Nationality</th>
                <th>Passport</th>
                <th>Arrival</th>
                <th>Expiry</th>
                <th>Portrait</th>
                <th>Passport</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {map(applicantData, (row) => (
                <tr key={row.id}>
                  <td>{row.orderId}</td>

                  <td>{row.fullName}</td>
                  <td>{row.nationality}</td>
                  <td>{row.passportNumber}</td>
                  <td>{new Date(row.issue).toLocaleDateString("en-GB")}</td>
                  <td>{new Date(row.expiry).toLocaleDateString("en-GB")}</td>
                  <td style={{ width: "100px" }}>
                    <img
                      src={row.portraitData}
                      alt="portrait"
                      width={"100%"}
                      onClick={() => handleImageClick(row.portraitData)}
                    />
                  </td>
                  <td style={{ width: "100px" }}>
                    <img
                      src={row.passportData}
                      alt="passport"
                      width={"100%"}
                      onClick={() => handleImageClick(row.passportData)}
                    />
                  </td>
                  <td>
                    <td>
                      <button
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={() =>
                          updateOrderStatus(
                            row.orderId,
                            generateApiUrls(row.orderId)
                          )
                        }
                      >
                        <i
                          className="bi bi-check-circle"
                          style={{ color: "green" }}
                        ></i>
                      </button>
                      <button
                        style={{ border: "none", backgroundColor: "white" }}
                        onClick={() =>
                          updateOrderStatus(
                            row.orderId,
                            generateApiUrls(row.orderId)
                          )
                        }
                      >
                        <i
                          className="bi bi-x-circle"
                          style={{ color: "red" }}
                        ></i>
                      </button>
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        {selectedImage && (
          <Popup imageURL={selectedImage} onClose={handlePopupClose} />
        )}
      </Card>
    </>
  );
};

export default Applicant;
